export type TranslationMethod = 'manual' | 'auto';

export enum DashboardUrlPath {
  MANAGE_LANGUAGES = 'multilingual/manage-languages',
  TRANSLATION_MANAGER = 'multilingual',
  SETTINGS = 'multilingual/settings',
  OLD_ML_DASHBOARD = 'multilingual',
}

export enum MultilingualPages {
  MANAGE_LANGUAGES = 'manage-languages',
  TRANSLATION_MANAGER = 'translation-manager',
  SETTINGS = 'settings',
}
